import Vue from "vue";

/*TITLE*/
document.title = "107 Los Almendrales | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "107 Los Almendrales";
Vue.prototype.$subtitle = "Limonar Alto";

/*INTRO*/
Vue.prototype.$promoter = "107 Los Almendrales";
Vue.prototype.$introSubtitle = "Limonar Alto";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-ac4-natural-1l-michigan-clear-_web--20230111040103.jpg";
Vue.prototype.$image_kitchen = "cocina-ac4-natural-1l-michigan-clearnogal-mooncoolxtone-bottega-antracita_web--20230111040112.jpg";
Vue.prototype.$image_bath1 = "banyo-1_ac6-natural-1l-michiganmarmol-carrara-blanco_web--20230111040129.jpg";
Vue.prototype.$image_bath2 = "banyo-2_ac6-natural-1l-michiganvela-naturallimit-vela-natural_web--20230111040136.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-ac4-natural-1l-michigan-clear-_web--20230111040103.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-ac4-natural-1l-michigan-clearnogal-mooncoolxtone-bottega-antracita_web--20230111040112.jpg",
  },
  {
    src: "cocina-ac4-natural-1l-michigan-clearblanco-nubolxtone-bottega-antracita_web--20230111040122.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1_ac6-natural-1l-michiganmarmol-carrara-blanco_web--20230111040129.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_ac6-natural-1l-michiganvela-naturallimit-vela-natural_web--20230111040136.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/LOS_ALMENDRALES/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/xMdCMVSKaUY";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=camino%20de%20los%20almendrales%20107%20málaga%2029013",
    text: "Camino de los almendrales 107, Málaga, 29013",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=alameda%20colón%2010%20málaga%2029001",
    text: "Alameda Colón, 10, Málaga, 29001",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:951464283/951464664",
    text: "951464283/951464664",
  },
  {
    icon: "mdi-email",
    link: "mailto:promociones.costa@gilmar.es",
    text: "promociones.costa@gilmar.es",
  },
];
